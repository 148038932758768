import LoyaltyContainer from '~/src/components/profile/account/loyalty/loyalty-container'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useLayout } from '~/src/hooks/layout/useLayout'

const Loyalty = () => {
  useLayout('profile')

  return (
    <>
      <DocumentTitleInjector pageName={'LOYALTY'} />
      <LoyaltyContainer />
    </>
  )
}

export default Loyalty
