import Title from '~/src/components/mui-wrappers/Title'
import { Box, SxProps } from '@mui/material'
import CustomerLoyaltyCurrentPoints from '~/src/components/profile/account/loyalty/current-points'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import LoyaltyContainerCta from '~/src/components/profile/account/loyalty/loyalty-container/cta'

interface Props {
  sx?: SxProps,
}

const LoyaltyContainerLeft = ({ sx }: Props ) => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(2, max-content)',
        gridTemplateAreas: '"title" ' +
          '"current-points" ' +
          '"text"',
        [muiTheme.breakpoints.down('md')]: {
          gridTemplateAreas: '"title" ' +
            '"text" ' +
            '"current-points"'
        },
        ...sx
      }}
    >
      <Title
        variant='h6'
        sx={{
          gridArea: 'title',
          textTransform: 'uppercase'
        }}
      >
        {t('profile.loyalty.page.title')}
      </Title>
      <Box sx={{ gridArea: 'current-points' }}>
        <CustomerLoyaltyCurrentPoints />
      </Box>
      <Box sx={{ gridArea: 'text' }}>
        <LoyaltyContainerCta />
      </Box>
    </Box>
  )
}

export default LoyaltyContainerLeft
