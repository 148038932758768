import Title from '~/src/components/mui-wrappers/Title'
import { Box } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_LOYALTY_HISTORY } from '~/src/stores/loyalty'
import { LoyaltyHistory as LoyaltyHistoryType } from '@eo-storefronts/eo-core'
import LoyaltyHistoryLine from '~/src/components/profile/account/loyalty/history/line'

const LoyaltyHistory = () => {
  const { t } = useTranslations()
  const loyaltyHistory = useEoValue(CUSTOMER_LOYALTY_HISTORY)

  return (
    <>
      <Title>{t('profile.loyalty.history')}</Title>
      <Box>
        {loyaltyHistory.map((entry: LoyaltyHistoryType) => (
          <LoyaltyHistoryLine key={entry.id} historyLine={entry} />
        ))}
      </Box>
    </>
  )
}

export default LoyaltyHistory
