import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'
import LoyaltyContainerProductCta from '~/src/components/profile/account/loyalty/loyalty-container/cta/product'
import LoyaltyContainerAmountCta from '~/src/components/profile/account/loyalty/loyalty-container/cta/amount'
import LoyaltyContainerPercentageCta from '~/src/components/profile/account/loyalty/loyalty-container/cta/percentage'

const LoyaltyContainerCta = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  if (
    firm?.settings.loyalty?.loyalty_type === 'product'
    || firm?.settings.loyalty?.loyalty_type === 'category'
  ) {
    return <LoyaltyContainerProductCta />
  }

  if (
    firm?.settings.loyalty?.loyalty_type === 'discount'
    && firm?.settings.loyalty?.discount_type === 'amount'
  ) {
    return <LoyaltyContainerAmountCta />
  }

  return <LoyaltyContainerPercentageCta />
}

export default LoyaltyContainerCta
