import { Box, SxProps } from '@mui/material'
import FirmLoyaltyRewards from '~/src/components/profile/account/loyalty/rewards'
import LoyaltyHistory from '~/src/components/profile/account/loyalty/history'

interface Props {
  sx?: SxProps,
}

const LoyaltyContainerRight = ({ sx }: Props) => (
  <Box
    sx={{
      display: 'grid',
      gap: 2,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, max-content)',
      gridTemplateAreas: '"rewards" ' +
          '"history"',
      ...sx
    }}
  >
    <Box sx={{ gridArea: 'rewards' }}>
      <FirmLoyaltyRewards />
    </Box>
    <Box sx={{ gridArea: 'history' }}>
      <LoyaltyHistory />
    </Box>
  </Box>
)

export default LoyaltyContainerRight
