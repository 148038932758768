import { Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const LoyaltyContainerPercentageCta = () => {
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()
  const firm = useEoValue(FIRM_SELECTOR)

  return (
    <Typography>
      {t('profile.loyalty.page.save_percentage', {
        amount: loyaltyService.getPointsLeftToEarn(),
        discount: firm?.settings.loyalty?.discount_value || ''
      })}<br />
      {t('profile.loyalty.page.point_value', {
        amountToSpend: `${firm?.settings.currency?.symbol || ''}${loyaltyService.getPointPerOrderPrice()}`
      })}
    </Typography>
  )
}

export default LoyaltyContainerPercentageCta
