import { alpha, Box, Typography } from '@mui/material'
import { LoyaltyHistory } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import LoyaltyHistoryLineTitle from '~/src/components/profile/account/loyalty/history/line/title'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLine = ({ historyLine }: Props) => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        p: 1,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: alpha(muiTheme.palette.text.primary, .2)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <LoyaltyHistoryLineTitle
          historyLine={historyLine}
          sx={{ fontWeight: 'bold' }}
        />
        <Typography>
          <b>{historyLine.points < 0 ? historyLine.points : `+${historyLine.points}`}</b> {t('profile.loyalty.points')}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        {historyLine.created}
      </Box>
    </Box>
  )
}

export default LoyaltyHistoryLine
