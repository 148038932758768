import { LoyaltyHistory } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'
import { SxProps, Typography } from '@mui/material'
import HtmlFireIcon from '~/src/components/icons/html-fire'

interface Props {
  historyLine: LoyaltyHistory,
  sx?: SxProps,
}

const LoyaltyHistoryLineTitle = ({ historyLine, sx }: Props) => {
  const { t } = useTranslations()

  if (historyLine.reward) {
    return (
      <Typography sx={sx}>
        {historyLine.reward} <HtmlFireIcon />
      </Typography>
    )
  }

  return (
    <Typography sx={sx}>
      {t('orders.order_reference')} #{historyLine.order_reference}
    </Typography>
  )
}

export default LoyaltyHistoryLineTitle
